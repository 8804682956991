<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { EyeSlashIcon } from '@heroicons/vue/24/solid';

const model = defineModel<string>({ required: true });

const input = ref<HTMLInputElement | null>(null);

const props = withDefaults(defineProps<{
  id?: string,
  type?: 'email' | 'password' | 'text' | 'tel' | 'url',
  placeholder?: string,
  isReadOnly?: boolean,
  pattern?: string,
}>(), {
  type: 'text',
  isReadOnly: false,
});

onMounted(() => {
  if (input.value?.hasAttribute('autofocus')) {
    input.value?.focus();
  }
});

const isValueHidden = ref<Boolean>(false);

const showHideIcon = computed(() : Boolean => props.type === 'password');

const toggleHideValue = () : void => {
  isValueHidden.value = !isValueHidden.value;
};

defineExpose({ focus: () => input.value?.focus() });

</script>

<template>
  <div class="relative flex items-center justify-center">
    <input
      :id="id"
      ref="input"
      v-model="model"
      :type="isValueHidden ? 'text' : type"
      :placeholder="placeholder"
      :pattern="pattern"
      :class="{
        'pr-12': showHideIcon,
        'ring-none border-none p-0 shadow-none outline-none ring-0 focus:ring-0' : isReadOnly,
        'shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600' : !isReadOnly,
      }"
      :readonly="isReadOnly"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
      v-bind="$attrs"
    >
    <EyeSlashIcon
      v-if="showHideIcon"
      id="toggle-hide-icon"
      :class="isValueHidden ? 'text-neutral-800' : 'text-gray-400'"
      class="absolute right-4 size-5 cursor-pointer"
      @click="toggleHideValue"
    />
  </div>
</template>
